// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout-module__limitedHeight___HyTYf{box-sizing:border-box}@media(max-width: 600px){.Layout-module__limitedHeight___HyTYf{min-height:calc(100svh - 40px)}}@media(min-width: calc(600px + 1px)){.Layout-module__limitedHeight___HyTYf{min-height:calc(100svh - 50px)}}", "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA,sCACE,qBAAA,CAEF,yBACE,sCACE,8BAAA,CAAA,CAGJ,qCACE,sCACE,8BAAA,CAAA","sourcesContent":["@import '~/mui/scss-vars/monitors.scss';\n\n.limitedHeight {\n  box-sizing: border-box;\n}\n@media (max-width: #{$sm}px) {\n  .limitedHeight {\n    min-height: calc(100svh - 40px);\n  }\n}\n@media (min-width: calc(#{$sm}px + 1px)) {\n  .limitedHeight {\n    min-height: calc(100svh - 50px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limitedHeight": "Layout-module__limitedHeight___HyTYf"
};
export default ___CSS_LOADER_EXPORT___;
