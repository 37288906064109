import { makeStyles } from '@mui/styles'
// import grey from '@material-ui/core/colors/grey'
// import { contentBottomMargin, breadCrumbsHeight, siteHeaderHeight } from '~/common/mui/baseStyles'

export const useStyles = makeStyles((_theme) => ({
  responsiveBlock: {
    // borderTop: '1px solid transparent',
    // border: 'none !important',
  },
}))
